// https://codesandbox.io/s/bitter-night-i5mhj?file=/src/index.tsx
// Kindly taken from the above source and Pilbara Solar project
//
// This will return the state of the menu, whether it is open or closed.
// Allowing the menu context to be changed in other components

import * as React from "react";

const globalStateContext = React.createContext(undefined);

function globalStateReducer(state, action) {
  switch (action.type) {
    case "setMenuOpen": {
      if (state.menuOpen !== action.data) {
        return { menuOpen: action.data };
      }
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function GlobalHeaderStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(globalStateReducer, {
    menuOpen: false,
  });
  const value = { state, dispatch };
  return (
    <globalStateContext.Provider value={value}>
      {children}
    </globalStateContext.Provider>
  );
}

function useHeaderState() {
  const context = React.useContext(globalStateContext);
  if (context === undefined) {
    // throw new Error();
  }
  return context;
}

export { GlobalHeaderStateProvider, useHeaderState };
