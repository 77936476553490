import React from "react";

export const ArrowSvg = () => {
  return (
    <svg
      width="14px"
      height="24px"
      viewBox="0 0 14 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Path 2</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Icons/Icon_ArrowRight_48x48"
          transform="translate(-17.000000, -12.000000)"
          stroke="#052337"
          strokeWidth="2.6"
        >
          <polyline
            id="Path-2"
            points="18.4599855 14.0657481 29.2311567 24 18.4599855 34.2145067"
          ></polyline>
        </g>
      </g>
    </svg>
  );
};
