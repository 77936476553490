import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./SocialLinks.scss";

const SocialLinksComponent = ({
  data: {
    siteSettings: {
      options: { socialLinks },
    },
  },
}) => {
  return (
    <div className="social-links">
      <ul>
        {socialLinks &&
          socialLinks.map((item, index) => (
            <li key={index}>
              <a
                className={item.type}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export function SocialLinks() {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              socialLinks {
                type
                url
              }
            }
          }
        }
      `}
      render={(data) => <SocialLinksComponent data={data} />}
    />
  );
}
