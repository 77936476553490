import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./ContactUsToday.scss";

export const ContactUsToday = ({ className }) => {
  return (
    <GatsbyLink to="/contact/" className={`${className} contact-us-today`}>
      <span className={`${className}__text contact-us-today__text`}>
        Contact us today
      </span>
      <svg
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Group</title>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group">
            <rect
              id="Rectangle-5"
              fill="#00AFAA"
              transform="translate(24.000000, 24.000000) scale(-1, 1) translate(-24.000000, -24.000000) "
              x="0"
              y="0"
              width="48"
              height="48"
              rx="24"
            ></rect>
            <polyline
              id="Path-2"
              stroke="#FFFFFF"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="19.4599855 14.0657481 30.2311567 24 19.4599855 34.2145067"
            ></polyline>
          </g>
        </g>
      </svg>
    </GatsbyLink>
  );
};
