import React, { useState } from "react";
import { navigate } from "gatsby";
import "./SearchBar.scss";

export const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    const searchBarQuery = e.target.searchbar.value;
    if (searchBarQuery) {
      navigate(`/search?s=${searchBarQuery}`, {
        state: {
          searchQuery: searchBarQuery,
        },
      });
    }
  };

  return (
    <div className={`search-bar ${isExpanded ? "expanded" : ""}`}>
      <span
        className="search-bar__magnify"
        onClick={() => setIsExpanded((prev) => !prev)}
      ></span>
      <form className="search-bar__form" onSubmit={(event) => onSubmit(event)}>
        <input
          id="searchbar"
          className="search-bar__form__input"
          type="text"
          autoComplete="off"
        />
        <button className="search-bar__form__button">Search</button>
      </form>
      <span
        className="search-bar__close"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span></span>
        <span></span>
      </span>
    </div>
  );
};
