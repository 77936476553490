import React from "react";
import { useHeaderState } from "../Header/HeaderContext";
import "./Hamburger.scss";

export const Hamburger = () => {
  // get the state of the menu whether it is open or closed
  const {
    dispatch,
    state: { menuOpen },
  } = useHeaderState();

  return (
    <button
      onClick={() => dispatch({ type: "setMenuOpen", data: !menuOpen })}
      type="button"
      className={`hamburger ${menuOpen ? "hamburger--open" : ""}`}
      aria-expanded={menuOpen}
      aria-label={menuOpen ? "Close navigation" : "Open navigation"}
    >
      <div className="hamburger__icon">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
};
