import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "../sass/global/styles.scss";

const Layout = ({ children, location, wordpressUrl }) => (
  <div id="layout">
    <Header location={location} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
);

export default Layout;
