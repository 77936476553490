import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { SocialLinks } from "../SocialLinks";
import { Logo } from "../Logo";
import GatsbyLink from "../GatsbyLink";
import { decodeEntities } from "../../utils/helpers";
import footerAccreditation from "../../images/footer-accreditation.png";
import { animateScroll as scroll } from "react-scroll";
import "./Footer.scss";

const FooterComponent = ({ data, location }) => {
  const { legalNavigation, siteSettings, wordpressWpSettings } = data;
  const { items: legalNav } = legalNavigation;

  const currentYear = new Date().getFullYear();
  const [isHomePage, setHomePage] = useState(false);

  useEffect(() => {
    location.pathname !== "/" ? setHomePage(false) : setHomePage(true);
  }, [location]);

  return (
    <footer className="footer">
      <div className="footer__main layout">
        <div className="footer__main__wrapper">
          <button
            type="button"
            className="footer__to-top-btn"
            onClick={() => scroll.scrollToTop()}
          />
          <div className="footer__content">
            <div className="footer__social-accrediations">
              <Logo className="footer__logo" />
              {siteSettings.options.socialLinksFooter && (
                <>
                  <p className="footer__title">Follow us</p>
                  <SocialLinks />
                </>
              )}
              <p className="footer__title">Email</p>
              <a
                href={`mailto:${decodeEntities("sales@aquip.com.au")}`}
                className="footer__email"
              >
                {decodeEntities("sales@aquip.com.au")}
              </a>
              <p className="footer__title">Our Accreditations</p>
              <img
                src={footerAccreditation}
                alt="ISO 9001 certified. ISO 4801 certified. ISO 41001 certified"
              />
            </div>
            {Object.keys(siteSettings.options.locations).length > 0 &&
              siteSettings.options.locations?.map((location, index) => {
                return (
                  <div key={index} className="footer__locations">
                    <span className="footer__title">
                      {location?.addressDisplay}
                    </span>
                    <address className="footer__address">
                      {location?.addressStreet}
                      <br />
                      {location?.addressCity}, {location?.addressRegion}{" "}
                      {location?.addressPostCode}
                    </address>
                    <a
                      href={`tel:${location?.phone.replace(/[^A-Z0-9]/gi, "")}`}
                      className="footer__phone"
                      aria-label="Call Now"
                    >
                      {location?.phone}
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="footer__legal layout">
        <div className="footer__legal__wrapper">
          <div>
            <span>
              © {currentYear}{" "}
              <span className="m-only">{wordpressWpSettings.title}</span>
            </span>
          </div>
          {legalNav && (
            <div className="footer__legal__navigation">
              <ul>
                {Object.keys(legalNav).length > 0 &&
                  legalNav?.map((item, index) => {
                    return (
                      <li key={index}>
                        <GatsbyLink to={item.url} decode={true}>
                          {item.title}
                        </GatsbyLink>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
          <div className="footer__legal__distl">
            {isHomePage ? (
              <span className="distl">
                Website by{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://distl.com.au/"
                >
                  Distl
                </a>
              </span>
            ) : (
              <span className="distl">Website by Distl</span>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export function Footer({ location }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          legalNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "legal-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                addressDisplay
                addressStreet
                addressCity
                addressRegion
                addressPostCode
                email
                phone
              }
              socialLinksFooter
            }
          }
          wordpressWpSettings {
            title
          }
        }
      `}
      render={(data) => <FooterComponent data={data} location={location} />}
    />
  );
}
