import React, { useState, useEffect, useRef } from "react";
import GatsbyLink from "../GatsbyLink";
import { ContactUsToday } from "../ContactUsToday";
import { NavItem } from "./NavItem";

import "./HeaderNavDesktop.scss";
import "./HeaderNavMobile.scss";

export const HeaderNav = ({
  navItems,
  onArrowClick,
  openChild,
  phoneNumbers,
}) => {
  return (
    <>
      <ul className="header__navigation">
        {Object.keys(navItems).length > 0 &&
          navItems?.map((navItem, index) => {
            return (
              <NavItem
                key={index}
                item={navItem}
                index={index}
                onArrowClick={onArrowClick}
                openChild={openChild}
              />
            );
          })}
        <li className="header-navigation__item--contact">
          <ContactUsToday className="header__navigation--contact-btn" />
        </li>
      </ul>
      <div className="header__naviation__mobile-contact">
        {Object.keys(phoneNumbers).length > 0 &&
          phoneNumbers?.map((location, index) => {
            return (
              <span
                key={index}
                className="header__naviation__mobile-contact__details"
              >
                <span className="header__naviation__mobile-contact__details__region">
                  {location.addressRegion}
                </span>
                <a
                  href={`tel:${location?.phone.replace(/[^A-Z0-9]/gi, "")}`}
                  className="header__naviation__mobile-contact__details__phone"
                  aria-label="Call Now"
                >
                  {location?.phone}
                </a>
              </span>
            );
          })}
      </div>
    </>
  );
};
