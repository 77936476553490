import React from "react";
import GatsbyLink from "../GatsbyLink";
import HiFraserGroup from "../../images/logos/hifrasergroup.svg";
import Aquip from "../../images/logos/aquip.svg";
import AquipFlowServices from "../../images/logos/aquipflowservices.svg";
import HiFraser from "../../images/logos/HiFraserBlue.svg";
import Ea from "../../images/logos/ea.svg";
import Pac from "../../images/logos/pac.svg";
import "./GroupLogos.scss";

export const GroupLogos = () => (
  <div className="group-logos layout-wide">
    <div className="group-logos__wrapper">
      <GatsbyLink
        to="https://www.hifraser.com.au/about-us/our-companies/"
        className="group-parent"
      >
        <img
          src={HiFraserGroup}
          className="hifraser"
          alt="Parent company logo"
        />
      </GatsbyLink>
      <GatsbyLink to="https://www.aquip.com.au" className="group-logo">
        <img src={Aquip} className="aquip" alt="Child company logo" />
      </GatsbyLink>
      <GatsbyLink to="https://www.aquip.com.au" className="group-logo">
        <img
          src={AquipFlowServices}
          className="aquipflowservices"
          alt="Child company logo"
        />
      </GatsbyLink>
      <GatsbyLink
        to="https://www.easternautomation.com.au"
        className="group-logo"
      >
        <img src={Ea} className="ea" alt="Child company logo" />
      </GatsbyLink>
      <GatsbyLink to="https://www.hifraser.com.au/" className="group-logo">
        <img src={HiFraser} className="hif" alt="Child company logo" />
      </GatsbyLink>
      <GatsbyLink to="https://pipact.com.au" className="group-logo">
        <img src={Pac} className="pac" alt="Child company logo" />
      </GatsbyLink>
      <a
        href="https://tidalfluidpower.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img
          src="/logos/tfp_logo.svg"
          className="tfp"
          alt="Child company logo"
        />
      </a>
    </div>
  </div>
);
