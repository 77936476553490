import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Logo.scss";

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo-link" aria-label="Logo">
      <svg
        width="144px"
        height="39px"
        viewBox="0 0 144 39"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Group 13</title>
        <defs>
          <polygon
            id="path-1"
            points="0 0 23.365088 0 23.365088 31.2989943 0 31.2989943"
          ></polygon>
          <polygon
            id="path-3"
            points="0 0 38.481699 0 38.481699 39 0 39"
          ></polygon>
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop/Menu/SearchFunction-Copy"
            transform="translate(-72.000000, -30.000000)"
          >
            <g id="Group-13" transform="translate(72.000000, 30.000000)">
              <path
                d="M96.3931452,1.30551935 L96.2491382,1.15967979 L96.08173,1.08129102 L95.9449233,1.06670706 L88.5213605,1.06670706 L88.4079549,1.06670706 C88.3413517,1.0876715 88.2772685,1.11647481 88.2171456,1.15238781 L88.0729585,1.29822738 C88.0383969,1.35565171 88.0099555,1.41672202 87.9885344,1.48052684 L87.9885344,1.59719849 L87.9885344,19.7013579 C87.9885344,23.5679294 87.9885344,27.2139186 84.3883585,27.2139186 C82.5360679,27.2139186 80.2481561,26.420916 80.2481561,22.4650177 L80.2481561,1.59719849 C80.2481561,1.29512828 80.0064043,1.05030011 79.7081297,1.05030011 L72.2899672,1.05030011 C71.9916926,1.05030011 71.7499408,1.29512828 71.7499408,1.59719849 L71.7499408,24.5104176 C71.7499408,28.8354723 75.2086298,32.3432785 79.4795185,32.3492944 L80.635175,32.3492944 C84.0337411,32.3492944 86.404457,31.138826 87.9777339,29.0679041 C87.9777339,30.8801431 89.4284248,32.3492944 91.2178923,32.3492944 L95.9449233,32.3492944 C96.2431979,32.3492944 96.4849497,32.1044663 96.4849497,31.802396 L96.4849497,1.59719849 L96.4687489,1.47505785 L96.3931452,1.30551935 Z"
                id="Fill-1"
                fill="#00AFAA"
              ></path>
              <path
                d="M112.268013,1.04844066 L104.849851,1.04844066 C104.551756,1.04844066 104.309824,1.29326883 104.309824,1.59533904 L104.309824,31.7987136 C104.309824,32.1007838 104.551756,32.345612 104.849851,32.345612 L112.268013,32.345612 C112.566288,32.345612 112.808039,32.1007838 112.808039,31.7987136 L112.808039,1.59533904 C112.808039,1.29326883 112.566288,1.04844066 112.268013,1.04844066"
                id="Fill-3"
                fill="#00AFAA"
              ></path>
              <g id="Group-7" transform="translate(120.634912, 1.050282)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-6"></g>
                <path
                  d="M8.5054157,15.6485857 L8.5054157,3.11914376 C11.6895913,3.11914376 14.874127,3.11914376 14.874127,9.37748423 C14.874127,15.6358247 11.6895913,15.6485857 8.5054157,15.6485857 M13.2918496,0 L0.540026394,0 C0.241751816,0 0,0.244828175 0,0.546898381 L0,30.7520959 C0,31.0541661 0.241751816,31.2989943 0.540026394,31.2989943 L7.95818896,31.2989943 C8.25646354,31.2989943 8.49821535,31.0541661 8.49821535,30.7520959 L8.49821535,18.7750214 C18.3104949,18.7750214 23.365142,17.212715 23.365142,9.38295322 C23.365142,1.5531914 17.5508578,0 13.2918496,0"
                  id="Fill-5"
                  fill="#00AFAA"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <g id="Group-10" transform="translate(32.592621, 0.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g id="Clip-9"></g>
                <path
                  d="M16.4960126,29.2227858 C11.4125641,29.2227858 9.53687244,23.6225464 9.53146049,16.7061048 C9.52607191,9.78966331 11.3909631,4.18030892 16.4744115,4.16937095 C21.5902616,4.16937095 23.4335516,9.77143337 23.4407731,16.6878749 C23.4479523,23.6043164 21.6118626,29.2191398 16.4960126,29.2227858 M38.1762722,35.7347048 C33.8002583,35.4501353 32.2557828,35.0055069 26.5837056,29.8881788 C31.3240573,26.1730981 33.6976533,20.1437258 32.7814085,14.1447974 C31.7283571,6.96037569 25.9968769,1.20153574 18.8973299,0.180658765 C9.88608951,-1.16434665 1.50451986,5.14321467 0.176234936,14.2689433 C0.0129669566,15.3909965 -0.0370754892,16.5270868 0.0270076429,17.659531 C0.401425943,24.4777131 5.02765205,30.4204932 11.4269648,32.5643349 C13.9460079,33.4147619 16.6344393,33.6176612 19.2501472,33.1549851 L19.7433713,33.6216717 C24.6936132,38.2976529 28.8086144,41.5352913 38.3166791,36.3452257 C38.4720267,36.2573573 38.5274694,36.0584686 38.4407051,35.9013265 C38.3867025,35.8034317 38.2867976,35.7403561 38.1762722,35.7347048"
                  id="Fill-8"
                  fill="#00AFAA"
                  mask="url(#mask-4)"
                ></path>
              </g>
              <path
                d="M10.3812873,19.8179748 L13.9418614,9.91364517 L17.5006353,19.8179748 L10.3812873,19.8179748 Z M28.6485802,26.4208613 L20.9262027,4.86759612 L19.7543455,1.60078979 C19.6355396,1.27192156 19.3261045,1.05334451 18.9803076,1.05389039 L11.6503494,1.05389039 C11.3045525,1.05389039 10.9956574,1.27228616 10.8763115,1.60078979 L9.72065506,4.79103034 L1.22964006,28.4389163 L0.0253811967,31.7877574 C-0.0531026392,32.0043292 0.0565227188,32.244053 0.270193162,32.3237178 C0.315735388,32.3404894 0.363977746,32.3492397 0.412400112,32.3492397 L5.31043951,32.3492397 C5.65659642,32.3496043 5.96639157,32.1312096 6.08627742,31.8023414 L7.28693611,28.4427446 L8.88361415,24.0017475 L18.9857079,24.0017475 L19.8551504,26.4245073 L20.9964062,29.6056329 C21.5888151,31.2543492 23.1358107,32.3521565 24.8683954,32.3528943 L30.1876554,32.3528943 C30.4153665,32.3543441 30.6009556,32.168581 30.6024063,31.9379722 C30.6027557,31.8874752 30.5939352,31.8371606 30.5764744,31.7895804 L28.6485802,26.4208613 Z"
                id="Fill-11"
                fill="#00AFAA"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </GatsbyLink>
  );
};
