import { useStaticQuery, graphql } from "gatsby";

/**
 * Retrieve all of the page names and slugs
 * This is being used in the breadcrumb pages, the client wants to retain the uppercase & lowercase in the product titles
 */

export const useAllPageName = () => {
  const {
    allWordpressPage,
    allWordpressPost,
    allWordpressWpCaseStudies,
    allWordpressWpProducts,
  } = useStaticQuery(
    graphql`
      query AllPages {
        allWordpressPage {
          nodes {
            title
            slug
            path
            id
          }
        }
        allWordpressPost {
          nodes {
            title
            slug
            path
            id
          }
        }
        allWordpressWpCaseStudies {
          nodes {
            title
            slug
            path
            id
          }
        }
        allWordpressWpProducts {
          nodes {
            title
            slug
            path
            id
          }
        }
      }
    `
  );
  return [
    ...allWordpressWpCaseStudies.nodes,
    ...allWordpressPage.nodes,
    ...allWordpressPost.nodes,
    ...allWordpressWpProducts.nodes,
  ];
};
